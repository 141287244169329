<template>
  <div class="components-grid">
    <div class="step-wrapper">
      <div class="description">
        Aktueller Schritt:
      </div>
      <div class="step">
        {{ currentStep }}
      </div>
    </div>

    <div class="process-step-wrapper">
      <SearchExtendedCustomerContracts
        v-if="currentStep == steps.SEARCH_CONTRACT_DATA"
        label="Portierungsstart - Vertragssuche *"
        :state="currentState.searchData"
        :isLoading="loading"
        @stateChanged="updateSearchState($event)"
        @inputValidationChanged="validationErrors = $event"
      />
      <PortingDataCheck
        v-if="currentStep == steps.PORTING_DATA"
        :state="currentState"
        :isLoading="loading"
        @stateChanged="currentState = $event"
        @inputValidationChanged="currentInputsValid = $event"
      />
      <PortingDataCheck
        v-if="currentStep == steps.CONFIRM"
        :state="currentState"
        :isLoading="loading"
        dataDisplay
        @stateChanged="currentState = $event"
        @inputValidationChanged="currentInputsValid = $event"
      />
    </div>
    <div class="step-navigation-wrapper">
      <v-btn
        v-if="displayBackButton"
        class="backButton"
        color="primary"
        large
        @click="updateFsm(availableSignals.BACKWARD)"
      >
        Zurück
      </v-btn>
      <v-btn
        v-if="displayForwardButton"
        class="forwardButton"
        :disabled="!currentInputsValid"
        color="primary"
        large
        @click="updateFsm(availableSignals.FORWARD)"
      >
        {{
          currentStep == steps.SEARCH_CONTRACT_DATA
            ? 'Vertrag suchen'
            : 'Nächster Schritt'
        }}
      </v-btn>
      <v-btn
        v-if="displayCompleteButton"
        class="forwardButton"
        :disabled="!currentInputsValid || processStarting"
        :loading="processStarting"
        color="primary"
        large
        @click="startProcess()"
      >
        Portierung starten
      </v-btn>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';

import PortingDataCheck from './PortingDataCheck.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import SearchContractSelection from '../SearchContractSelection.vue';
import NotificationObject from '@/main/NotificationObject.js';
import SearchExtendedCustomerContracts from '../SearchExtendedCustomerContracts.vue';

export default {
  name: 'PhoneNumberPorting',
  components: {
    SearchContractSelection,
    SearchExtendedCustomerContracts,
    PortingDataCheck
  },
  data: () => ({
    currentState: null,
    currentStep: null,
    currentInputsValid: true,
    contractDataLoading: false,
    processStarting: false,
    searchState: null
  }),
  computed: {
    loading() {
      return this.contractDataLoading;
    },
    displayBackButton() {
      return this.currentStep !== this.steps.SEARCH_CONTRACT_DATA;
    },
    displayForwardButton() {
      return this.currentStep !== this.steps.CONFIRM;
    },
    displayCompleteButton() {
      return this.currentStep === this.steps.CONFIRM;
    }
  },
  mounted: function () {
    this.initState();
  },
  created() {
    this.steps = {
      SEARCH_CONTRACT_DATA: 'Vertragssuche',
      PORTING_DATA: 'Auswahl Portierungsdaten',
      CONFIRM: 'Auswahl bestätigen'
    };
    this.availableSignals = {
      BACKWARD: {
        id: 'BACKWARD'
      },
      FORWARD: {
        id: 'FORWARD'
      }
    };
  },
  methods: {
    updateSearchState(state) {
      this.currentState.searchData = state;
      if (
        this.currentState.searchData.selectedContract === undefined ||
        this.currentState.searchData.selectedContract === null
      ) {
        this.currentInputsValid = false;
      } else {
        this.currentInputsValid = true;
      }
    },
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    initState: function () {
      this.currentState = {
        contractId: null,
        contactInformation: null,
        portingData: {
          portAllNumbers: false,
          portingDate: null,
          phoneNumbers: [],
          selectedCarrier: null,
          withCancellation: true
        },
        searchData: {
          selectedContract: undefined,
          customerId: undefined,
          customerContracts: undefined,
          recallDate: null,
          searchState: {
            customerId: undefined,
            contractId: undefined,
            firstName: undefined,
            lastName: undefined,
            contactAddress: {
              zipCode: undefined,
              city: undefined,
              street: undefined,
              houseNumber: undefined,
              houseNumberSupplement: undefined
            }
          }
        }
      };
      this.currentStep = this.steps.SEARCH_CONTRACT_DATA;
    },
    updateFsm: function (signal) {
      switch (this.currentStep) {
        case this.steps.SEARCH_CONTRACT_DATA:
          // only forward possible
          this.retrieveContractInfo();
          break;
        case this.steps.PORTING_DATA:
          if (signal === this.availableSignals.BACKWARD) {
            this.currentStep = this.steps.SEARCH_CONTRACT_DATA;
          } else {
            this.currentStep = this.steps.CONFIRM;
          }
          break;
        case this.steps.CONFIRM:
          // only backwards possible
          this.currentStep = this.steps.PORTING_DATA;
      }
      window.scrollTo(0, 0);
    },
    retrieveContractInfo: function () {
      let selectedContractId = null;
      if (
        !(
          this.currentState.searchData.selectedContract === undefined ||
          this.currentState.searchData.selectedContract === null
        )
      ) {
        selectedContractId =
          this.currentState.searchData.selectedContract.contractId;
      }
      if (!selectedContractId) {
        this.currentState.contractId = null;
        return;
      }
      this.contractDataLoading = true;
      this.currentState.contractId = selectedContractId;
      HTTP.get(`/porting/contractInfo/${selectedContractId}`)
        .then((result) => {
          // this.currentState.contractInfo = result.data;
          this.transformAddressData(result.data);
          this.currentState.portingData.phoneNumbers =
            result.data.phoneNumbers.map((numberString) => ({
              number: numberString,
              active: false
            }));
          this.currentState.portingData.selectedCarrier =
            result.data.carrierDto;
          // Update step
          this.currentStep = this.steps.PORTING_DATA;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Kundendaten konnten nicht geladen werden.'
            )
          )
        )
        .finally(() => {
          this.contractDataLoading = false;
        });
    },
    transformAddressData: function (contractInfo) {
      this.currentState.contactInformation = {
        customerId: contractInfo.customerId,
        firstName: contractInfo.contactInformation.firstName,
        lastName: contractInfo.contactInformation.lastName,
        salutation: contractInfo.contactInformation.salutation,
        birthDate: contractInfo.contactInformation.birthDate,
        street: contractInfo.contactInformation.street,
        houseNumber: contractInfo.contactInformation.houseNumber,
        houseNumberSupplement:
          contractInfo.contactInformation.houseNumberSupplement,
        city: contractInfo.contactInformation.city,
        zipCode: contractInfo.contactInformation.zipCode,
        phone: contractInfo.contactInformation.phone,
        mobile: contractInfo.contactInformation.cellPhoneNumber,
        email: contractInfo.contactInformation.email
      };
    },
    startProcess: function () {
      console.log('starting process!');
      this.processStarting = true;
      const postData = {
        contractId: this.currentState.contractId,
        requestedPortingDate: this.currentState.portingData.portingDate,
        portAllPhoneNumbers: this.currentState.portingData.portAllNumbers,
        portPhoneNumbersWithCancellation:
          this.currentState.portingData.withCancellation,
        phoneNumbers: this.currentState.portingData.phoneNumbers
          .filter((number) => number.active === true)
          .map((number) => number.number),
        dimariCarrierDto: this.currentState.portingData.selectedCarrier,
        numberRequestPhoneNumbers: this.currentState.portingData.numberRequestPhoneNumbers
      };
      if (this.currentState.alternativeCustomer) {
        postData.alternativeCustomer = this.currentState.alternativeCustomer;
      }
      HTTP.post('/porting/start', postData)
        .then((resp) => {
          this.showMessage(
            'success',
            'Der Prozess wurde erfolgreich gestartet.'
          );
          this.$router.push('/');
        })
        .catch((err) => {
          console.log('error', err);
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Der Prozess konnte nicht gestartet werden.'
            )
          );
        })
        .finally(() => {
          this.processStarting = false;
        });
    }
  }
};
</script>

<style scoped>
.components-grid {
  display: grid;
  grid-template-rows: max-content max-content auto max-content;
  height: calc(100% - 2px);
}

.title-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  color: var(--goe-fontColor-dark);
  font-weight: var(--goe-fontWeight-light);
  padding: var(--goe-spacing-2);
  padding-bottom: var(--goe-spacing-1_5);
  background: var(--goe-background-highlight);
}

.title-wrapper h2 {
  margin-top: var(--goe-spacing-0_5);

  font-size: var(--goe-fontSize-normal);
  color: var(--goe-fontColor-dark);
  text-transform: uppercase;
  font-weight: var(--goe-fontWeight-light);
}

.step-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: var(--goe-spacing-2);
  margin: var(--goe-spacing-2);
  margin-left: auto;
  width: max-content;
}

.process-step-wrapper {
  display: grid;
  grid-template-columns: auto;

  padding: var(--goe-spacing-2);
}

.step-navigation-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  margin: var(--goe-spacing-2);
  padding: var(--goe-spacing-0) var(--goe-spacing-2);
  grid-template-areas: 'back forward ';
}

.step-navigation-wrapper > .backwardButton {
  grid-area: backward;
}

.step-navigation-wrapper > .forwardButton {
  grid-area: forward;
}

.step-navigation-wrapper NormalButton:nth-of-type(1) {
  order: 2;
}

.step-navigation-wrapper NormalButton:nth-of-type(2) {
  order: 1;
}

.description {
  font-size: var(--goe-fontSize-small);
  color: var(--goe-fontColor-light);
  margin-bottom: var(--goe-spacing-0);
  margin-top: auto;
  width: max-content;
}

.step {
  color: var(--goe-fontColor-highlight);
  font-weight: var(--goe-fontWeight-bold);
  font-size: var(--goe-fontSize-normal);
}
</style>
